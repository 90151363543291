import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueRouter, { RouteConfig } from 'vue-router';
import CookiesPolicy from '../views/cookies-policy.vue';
import Error404 from '../views/error-404.vue';
import Home from '../views/home.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/polityka-cookies',
    name: 'cookies',
    component: CookiesPolicy,
  },
  {
    path: '*',
    name: 'error-404',
    component: Error404,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  // https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
