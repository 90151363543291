











import Vue from 'vue';

export default Vue.extend({
  name: 'main-box',
  props: {
    title: String,
    imgSrc: String,
  },
});
