































































import Vue from 'vue';
import pkg from '../package.json';

export default Vue.extend({
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      { httpEquiv: 'X-UA-Compatible', content: 'IE=edge' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no' },
      {
        name: 'description',
        content:
          'Warsztat blacharsko-lakierniczy Auto-PS - wykonujemy profesjonalne naprawy powypadkowe samochodów osobowych. ' +
          'Realizujemy naprawy bezgotówkowe, reprezentujemy naszych klientów przed ubezpieczycielem. ' +
          'Zapewniamy samochód zastępczy oraz skupujemy pojazdy powypadkowe.',
      },
      { name: 'version', content: process.env.VUE_APP_VERSION },
    ],
    link: [{ rel: 'preconnect', href: 'https://fonts.gstatic.com' }],
    titleTemplate: (chunk) => (chunk ? `${chunk} | ${pkg.description}` : pkg.description),
  },
});
