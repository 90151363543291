





































































































import MainBox from '@/components/main-box.vue';
import MainCarousel from '@/components/main-carousel.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'home',
  components: {
    MainBox,
    MainCarousel,
  },
});
